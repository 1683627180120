// mit dem code kann man festlegen wieviele cards in einem
// bootstrap card deck nebeneinander dargestellt werden.
// abgeguckt von: https://stackoverflow.com/a/48545164

// Bootstrap 4 breakpoints & gutter
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$grid-gutter-width: 30px !default;

// number of cards per line for each breakpoint
$cards-per-line: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 3
);

$margin-bottom-per-line: (
  xs: 15px,
  sm: 30px,
  md: 30px,
  lg: 30px,
  xl: 30px
);

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .card-deck {
      .card {
        flex: 0 0 calc(#{100%/map-get($cards-per-line, $name)} - #{$grid-gutter-width});
        margin-bottom: map-get($margin-bottom-per-line, $name);
      }
    }
  }
}
