//prefixe

$primary: #34495e;
$primary-hover: #2c3e50;
$secondary: #95a5a6;
$secondary-hover: #7f8c8d;
$light: #ecf0f1;
$light-hover: #bdc3c7;
$success: #2ecc71;
$success-hover: #27ae60;
$info: #3498db;
$info-hover: #2980b9;
$warning: #f1c40f;
$warning-hover: #f39c12;
$danger: #e74c3c;
$danger-hover: #c0392b;

.btn- {
  &primary {
    background-color: $primary !important;
    border-color: $primary !important;
    &:hover {
      background-color: $primary-hover !important;
      border-color: $primary-hover !important;
    }
  }
  &secondary {
    background-color: $secondary !important;
    border-color: $secondary !important;
    &:hover {
      background-color: $secondary-hover !important;
      border-color: $secondary-hover !important;
    }
  }
  &light {
    background-color: $light !important;
    border-color: $light !important;
    &:hover {
      background-color: $light-hover !important;
      border-color: $light-hover !important;
    }
  }
  &success {
    background-color: $success !important;
    border-color: $success !important;
    &:hover {
      background-color: $success-hover !important;
      border-color: $success-hover !important;
    }
  }
  &info {
    background-color: $info !important;
    border-color: $info !important;
    &:hover {
      background-color: $info-hover !important;
      border-color: $info-hover !important;
    }
  }
  &warning {
    background-color: $warning !important;
    border-color: $warning !important;
    &:hover {
      background-color: $warning-hover !important;
      border-color: $warning-hover !important;
    }
  }
  &danger {
    background-color: $danger !important;
    border-color: $danger !important;
    &:hover {
      background-color: $danger-hover !important;
      border-color: $danger-hover !important;
    }
  }
}

.text- {
  &primary {
    color: $primary !important;
  }
  &secondary {
    color: $secondary !important;
  }
  &light {
    color: $light !important;
  }
  &success {
    color: $success !important;
  }
  &info {
    color: $info !important;
  }
  &warning {
    color: $warning !important;
  }
  &danger {
    color: $danger !important;
  }
}
