.sync-new{
  color: $color-new !important;
  background-color: $color-new-bg !important;
  border-color: $color-new-border !important;
}
.sync-hidden{
  display:none;
}
.sync-deprecated{
  color: $color-deprecated !important;
  background-color: $color-deprecated-bg !important;
  border-color: $color-deprecated-border !important;
}
.sync-updated{
  color: $color-updated !important;
  background-color: $color-updated-bg !important;
  border-color: $color-updated-border !important;
}