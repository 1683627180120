.flag {
  height: auto;
  width: 20px;
}

table {
  &.table {
    font-size: 14px;

    select {
      option {
        text-transform: capitalize;
      }
    }
  }

  &.sorter {
    thead {
      tr {
        .form-control {
          &.disabled {
            display: none;
          }
        }
      }
    }
  }
}
