.card-title {
  min-height: 2.5rem;
}

.card-body {
  .text-center {
    .btn {
      & + .btn {
        margin-left: 2%;
      }
    }
  }
}
