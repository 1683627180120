$footer-height: 60px;
$footer-background-color: #f5f5f5;

/* json-sync */
$color-new: #155624;
$color-new-bg: #d4edda;
$color-new-border:#c3e6cb;

$color-deprecated:#721c24;
$color-deprecated-bg:#f8d7da;
$color-deprecated-border:#f5c6cb;

$color-updated:#0f4157;
$color-updated-bg:#58c7ed;
$color-updated-border:#1e95e6;
/* /json-sync */