header {
  nav {
    .navbar-brand {
      img {
        max-height: 40px;
        width: auto;
        margin-right: 10px;
      }
    }

    ul {
      &.navbar-nav {
        li {

          border-bottom: 2px solid $light;

          &.dropdown {
            &:hover {
              .dropdown-menu {
                display: block;
                margin-top: 1px;
              }
            }

            .dropdown-item {
              &.active,
              &:active {
                background-color: $primary;
              }

            }
            & > a {
              &::after {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: .3em;
                vertical-align: middle;
                content: '';
                border-top: .3em solid;
                border-right: .3em solid transparent;
                border-left: .3em solid transparent;
              }
            }
          }

          &.active {
            color: rgba(0, 0, 0, .9);
            border-bottom: 2px solid $primary;
          }
        }
      }
    }
  }
}
