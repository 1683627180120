// layout/_footer.scss
html {
  min-height: 100%;
  position: relative;
}

body {
  main {
    padding-bottom: calc(#{$footer-height} * 2);
  }
}

.footer {
  width: 100%;
  bottom: 0;
  height: $footer-height;
  line-height: $footer-height;
  background-color: $footer-background-color;
  position: absolute;
  text-align: center;
}

@media screen and (min-width: 768px) {
  footer [class^="col-"] {
    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }
  }
}
